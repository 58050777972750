import React from "react";

const CardResusable = ({ title, children }) => {
  return (
    <div className="card">
      <div className="card-header">{title}</div>
      <div className="card-body">
        <div className="container-fluid">{children}</div>
      </div>
    </div>
  );
};

export default CardResusable;
