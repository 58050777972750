import React from "react";
import Chart from "react-apexcharts";

const BarLineGraph = ({
  chart_title,
  chart_type,
  data,
  cardCss,
  widthType,
  options,
}) => {
  // Check if data is defined
  if (!data) {
    return <div>No data available</div>;
  }
  console.log("this is a chart_type", chart_type);
  // Check if data.dataset is defined and not empty
  const dataset = data.dataset;
  if (!dataset || dataset.length === 0) {
    return <div>No data available at this time</div>;
  }

  // Render bar and line chart
  return (
    <div style={cardCss}>
      <h4 className="ml-3">{chart_title}</h4>

      <Chart
        options={options}
        series={dataset}
        type={chart_type}
        width="100%"
        height={options?.chart?.height}
      />
    </div>
  );
};

export default BarLineGraph;
