import React from "react";

const TableComponent = ({ headers, rows }) => {
  return (
    <div className="table-container">
      <div className="table-responsive">
        <table className="table-agent table mb-0">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index} className="fs-12 px-2">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex} className="fs-10 px-2 py-2">
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
