import BivarinatChartTesting from "../components/BivarinatChartTesting";
import AreaChartMain from "../components/charts/ AreaChartMain";
import LineChartTesting from "../components/charts/LineChartMain/  index";
import DonutChartTesting from "../components/charts/DonutChartTesting/ index";
import ScatterChartTesting from "../components/charts/ScatterChartTesting/ index";
import CountOfChart from "../components/charts/CountOfChart";
import RadarChart from "../components/charts/ RadarChart";
import HeatmapChartComponent from "../components/charts/HeatmapChartComponent";
import SalesAndProfitCard from "../components/tabeldata/SalesAndProfitCard";
import BarLineGraph from "../components/charts/BarLineGraph";

// Mapping object for chart types and components
const chartComponents = {
  area: AreaChartMain,
  line: LineChartTesting,
  bar: BivarinatChartTesting,
  donut: DonutChartTesting,
  scatter: ScatterChartTesting,
  count: CountOfChart,
  radar: RadarChart,
  // eslint-disable-next-line no-undef
  heatmap: HeatmapChartComponent,
  table_one: SalesAndProfitCard,
  bar_line: BarLineGraph,
};

export default chartComponents;
