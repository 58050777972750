// import React, { useEffect, useState } from "react";
// import Chart from "react-apexcharts";

// const AreaChart = () => {
//   const [chartData, setChartData] = useState(null);
//   const [error, setError] = useState(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const optionsResponse = await fetch("http://localhost:3500/options");
//         const seriesResponse = await fetch("http://localhost:3500/series");

//         if (!optionsResponse.ok || !seriesResponse.ok) {
//           throw new Error("Failed to fetch chart data.");
//         }

//         const optionsData = await optionsResponse.json();
//         const seriesData = await seriesResponse.json();

//         setChartData({ options: optionsData, series: seriesData });
//       } catch (error) {
//         console.error("Error fetching chart data:", error);
//         setError(true);
//       }
//     };

//     fetchData();
//   }, []);

//   if (error) {
//     return <div>Error fetching chart data.</div>;
//   }

//   if (!chartData) {
//     return <div>Loading chart data...</div>;
//   }

//   const { options, series } = chartData;

//   return <Chart options={options} series={series} type="area" height={350} />;
// };

// export default AreaChart;

// Second Way
import React from "react";
import Chart from "react-apexcharts";
import areaChartData from "../data/chartdata/areaChartData.json";
const AreaChart = () => {
  const { options, series } = areaChartData;

  return <Chart options={options} series={series} type="area" height={350} />;
};

export default AreaChart;

// As a prpos
// import React from "react";
// import Chart from "react-apexcharts";

// const AreaChart = ({ options, series }) => {
//   return <Chart options={options} series={series} type="area" />;
// };

// export default AreaChart;

// import React from "react";
// import Chart from "react-apexcharts";

// const AreaChart = ({ options, series }) => {
//   const { chart } = options;
//   const { height } = chart;

//   // Create a copy of the options object
//   const updatedOptions = { ...options };

//   // Update the height property if it exists in the JSON, otherwise use a default value
//   if (height) {
//     updatedOptions.chart.height = height;
//   } else {
//     updatedOptions.chart.height = 350; // Default height is 350 if not provided in the JSON
//   }

//   return <Chart options={updatedOptions} series={series} type="area" />;
// };

// export default AreaChart;

// import React from "react";
// import Chart from "react-apexcharts";

// const AreaChart = ({ options, series }) => {
//   const { chart } = options;
//   const { height } = chart;

//   const updatedOptions = {
//     ...options,
//     chart: {
//       ...chart,
//       height: height || 350, // Default height is 350 if not provided in the JSON
//     },
//   };

//   return <Chart options={updatedOptions} series={series} type="area" />;
// };

// export default AreaChart;

// import React from "react";
// import Chart from "react-apexcharts";

// const AreaChart = ({ options, series }) => {
//   const { chart } = options;
//   const { height } = chart;

//   // Create a copy of the options object
//   //   const updatedOptions = { ...options };
//   const updatedOptions = {
//     ...options,
//     chart: {
//       height: height || 350, // Default height is 350 if not provided in the JSON
//     },
//   };
//   console.log("this is a height :", updatedOptions.height);

//   return <Chart options={updatedOptions} series={series} type="area" />;
// };

// export default AreaChart;

// import React, { useState } from "react";
// import Chart from "react-apexcharts";
// import FilterLogic from "./FilterLogic";

// const AreaChart = ({ options, series }) => {
//   const { chart } = options;
//   const { height } = chart;
//   const [filteredSeries, setFilteredSeries] = useState(series); // State for storing the filtered series

//   // Create a copy of the options object and update the height property
//   const updatedOptions = {
//     ...options,
//     chart: {
//       ...chart,
//       height: height || 350, // Default height is 350 if not provided in the JSON
//     },
//   };

//   // Event handler for handling filter changes
//   const handleFilterChange = (filteredSeries, selectedMonth) => {
//     setFilteredSeries(filteredSeries);
//     // Perform any additional actions based on the selectedMonth if needed
//     console.log("Selected Month:", selectedMonth);
//   };

//   return (
//     <div>
//       {/* Filter logic */}
//       <FilterLogic series={series} onFilter={handleFilterChange} />

//       {/* Chart */}
//       <Chart options={updatedOptions} series={filteredSeries} type="area" />
//     </div>
//   );
// };

// export default AreaChart;
