import React from "react";

// Dashboard
import CallDashboard from "../dashboard/CallDashboard";
import ProductManagement from "../dashboard/ProductManagement";
import IvrDashboard from "../dashboard/IvrDashboard";
import Chart from "../dashboard/Chart";
// eslint-disable-next-line no-unused-vars
// import Yougeh from "../dashboard/Yougeh";
const protectedRoutes = [
  { path: "dashboard/call", element: <CallDashboard /> },
  { path: "dashboard/product", element: <ProductManagement /> },
  { path: "dashboard/IVR", element: <IvrDashboard /> },
  { path: "dashboard/chart", element: <Chart /> },
  // { path: "dashboard/Yougeh", element: <Yougeh /> },
];

export default protectedRoutes;
