// export const chartData = [
//   {
//     id: 1,
//     show: true,
//     title: "Answered",
//     answered: 30,
//   },
//   {
//     id: 2,
//     show: true,
//     title: "Missed",
//     missed: 20,
//   },
//   {
//     id: 3,
//     show: true,
//     title: "Inbound",
//     inbound: 50,
//   },
//   {
//     id: 4,
//     show: true,
//     title: "Outbound",
//     outbound: 40,
//   },
// ];

export const chartData = {
  answered: 30,
  missed: 20,
  inbound: 25,
  outbound: 15,
};

// export const chartData = {
//   answered: {
//     value: 30,
//     icon: "",
//   },
//   missed: {
//     value: 20,
//     icon: "",
//   },
//   inbound: {
//     value: 25,
//     icon: "",
//   },
//   outbound: {
//     value: 15,
//     icon: "",
//   },
// };
