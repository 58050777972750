import React, { useRef } from "react";
import { CSVLink } from "react-csv";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ExportUtils = ({ exportData, exportPageRef }) => {
  const pageRef = useRef();

  //   const handlePDFExport = () => {
  //     const element = exportPageRef.current;

  //     if (!element) {
  //       console.log("Invalid element provided");
  //       return;
  //     }

  //     // const options = {
  //     //   scrollY: -window.scrollY,
  //     //   useCORS: true,
  //     //   useProxy: true,
  //     // };

  //     const options = {
  //       scrollY: -window.scrollY,
  //       useCORS: true,
  //       useProxy: true,
  //       foreignObjectRendering: true,
  //       foreignObjectOptions: {
  //         width: 2000, // Use the actual width of the element
  //         height: element.offsetHeight, // Use the actual height of the element
  //       },
  //     };

  //     html2canvas(element, {
  //       ...options,
  //     }).then((canvas) => {
  //       const pdf = new jsPDF("p", "mm", "a4");
  //       const pageWidth = pdf.internal.pageSize.getWidth();
  //       const pageHeight = pdf.internal.pageSize.getHeight();

  //       const canvasRatio = canvas.width / canvas.height;
  //       const pageRatio = pageWidth / pageHeight;

  //       let imgWidth = pageWidth;
  //       let imgHeight = pageHeight;

  //       if (canvasRatio > pageRatio) {
  //         imgHeight = imgWidth / canvasRatio;
  //       } else {
  //         imgWidth = imgHeight * canvasRatio;
  //       }

  //       let position = 0;
  //       let windowHeight = window.innerHeight;
  //       let remainingHeight = canvas.height;

  //       while (remainingHeight > 0) {
  //         const visibleHeight = Math.min(remainingHeight, windowHeight);
  //         const imgDataUrl = canvas.toDataURL(
  //           "image/png",
  //           1.0,
  //           0,
  //           position,
  //           imgWidth,
  //           visibleHeight
  //         );

  //         pdf.addImage(imgDataUrl, "PNG", 0, 0, pageWidth, pageHeight);

  //         position += visibleHeight;
  //         remainingHeight -= visibleHeight;

  //         // if (remainingHeight > 0) {
  //         //   pdf.addPage();
  //         //   windowHeight = window.innerHeight; // Reset window height for next page
  //         // }
  //       }

  //       pdf.save("chart_export.pdf");
  //     });
  //   };

  const handlePDFExport = () => {
    const element = exportPageRef.current;

    if (!element) {
      console.log("Invalid element provided");
      return;
    }

    const options = {
      scrollY: -window.scrollY,
      useCORS: true,
      useProxy: true,
      scale: 2, // Adjust the scale value as needed to capture the complete content
    };

    // Add a delay before capturing the content
    setTimeout(() => {
      html2canvas(element, options).then((canvas) => {
        const pdf = new jsPDF("p", "mm", "a4");
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        const canvasRatio = canvas.width / canvas.height;
        const pageRatio = pageWidth / pageHeight;

        let imgWidth = pageWidth;
        let imgHeight = pageHeight;

        if (canvasRatio > pageRatio) {
          imgHeight = imgWidth / canvasRatio;
        } else {
          imgWidth = imgHeight * canvasRatio;
        }

        let position = 0;
        let windowHeight = window.innerHeight;
        let remainingHeight = canvas.height;

        while (remainingHeight > 0) {
          const visibleHeight = Math.min(remainingHeight, windowHeight);
          const imgDataUrl = canvas.toDataURL(
            "image/png",
            1.0,
            0,
            position,
            imgWidth,
            visibleHeight
          );

          pdf.addImage(imgDataUrl, "PNG", 0, 0, pageWidth, pageHeight);

          position += visibleHeight;
          remainingHeight -= visibleHeight;
        }

        pdf.save("chart_export.pdf");
      });
    }, 1000); // Adjust the delay time as needed
  };

  return (
    <div className="export-buttons">
      {/* PDF Export */}
      <button className="btn btn-primary btn-export" onClick={handlePDFExport}>
        Export as PDF
      </button>

      {/* CSV Export */}
      <CSVLink
        data={exportData}
        filename="chart_export.csv"
        className="btn btn-success btn-export-csv"
      >
        Export as CSV
      </CSVLink>

      {/* Excel Export */}
      <ReactHTMLTableToExcel
        id="chart-export-xls-button"
        className="btn btn-warning btn-export-excel"
        table="chart-data-table"
        filename="chart_export"
        sheet="chart_export"
        buttonText="Export as Excel"
      />
    </div>
  );
};

export default ExportUtils;
