// import React from "react";
// import Chart from "react-apexcharts";

// const HeatmapChartComponent = ({
//   chart_title,
//   chart_type,
//   data,
//   cardCss,
//   widthType,
//   options,
// }) => {
//   console.log("this is the data but both chart", chart_type);

//   // Check if data is defined
//   if (!data) {
//     return <div>No data available</div>;
//   }

//   // Check if data.dataset is defined and not empty
//   const dataset = data.dataset;
//   console.log("this is a data of dataset :", dataset);
//   if (!dataset || dataset.length === 0) {
//     return <div>No data available at this time</div>;
//   }

//   return (
//     <div style={cardCss}>
//       <h4 className="ml-3">{chart_title}</h4>

//       <Chart
//         options={options}
//         series={dataset}
//         type={chart_type}
//         width="100%"
//         height={options?.chart?.height}
//       />
//     </div>
//   );
// };

// export default HeatmapChartComponent;

import React, { useState, useRef } from "react";
import Chart from "react-apexcharts";
import ChartExport from "../../ExportUtils/ChartExport/ChartExport";

import "./styles.css";

const HeatmapChartComponent = ({
  chart_title,
  chart_type,
  data,
  cardCss,
  widthType,
  options,
  series,
}) => {
  const [isMaximized, setIsMaximized] = useState(false);
  const chartRef = useRef();
  const originalChartOptions = options; // Store the original chart options
  const originalCardCss = cardCss; // Store the original card CSS

  const toggleMaximize = () => {
    setIsMaximized((prevMaximized) => !prevMaximized);
  };

  // Check if data is defined
  if (!data) {
    return <div>No data available</div>;
  }

  // Check if data.dataset is defined and not empty
  const dataset = data.dataset;
  if (!dataset || dataset.length === 0) {
    return <div>No data available at this time</div>;
  }

  const chartOptions = isMaximized ? originalChartOptions : options; // Use original options when not maximized
  const cardStyles = isMaximized
    ? {
        ...originalCardCss,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
      }
    : originalCardCss; // Update card styles when maximized

  // Calculate the height based on the aspect ratio of the chart
  const calculateHeight = () => {
    const aspectRatio = options.chart.height / options.chart.width;
    const width = window.innerWidth;
    const height = width * aspectRatio;
    return isMaximized ? height : options.chart.height;
  };

  const chartContainerStyle = {
    transform: isMaximized ? "scale(1.1)" : "scale(1)",
    transition: "transform 0.3s ease",
  };

  return (
    <div
      style={cardStyles}
      className={`chart-container ${isMaximized ? "maximized" : ""}`}
    >
      <div className="chrt_title_dots">
        <h4 className="ml-3">{chart_title}</h4>
        <div className="three_dots">
          <ChartExport chartData={dataset} exportPageRef={chartRef} />
        </div>
      </div>

      <div
        ref={chartRef}
        className="chart-wrapper"
        style={{ height: calculateHeight() }}
      >
        <div style={chartContainerStyle}>
          <Chart
            options={chartOptions}
            series={dataset}
            type={chart_type}
            width="100%"
            height={isMaximized ? "100%" : options?.chart?.height}
          />
        </div>
      </div>

      <div className="maximize-button" onClick={toggleMaximize}>
        {isMaximized ? (
          <i class="bi bi-fullscreen-exit"></i>
        ) : (
          <i class="bi bi-fullscreen"></i>
        )}
      </div>
    </div>
  );
};

export default HeatmapChartComponent;
