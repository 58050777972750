import React from "react";
import Chart from "react-apexcharts";

const ScatterChartTesting = ({
  chart_title,
  chart_type,
  data,
  cardCss,
  widthType,
  options,
}) => {
  console.log("this is the data but both chart", chart_type);

  // Check if data is defined
  if (!data) {
    return <div>No data available</div>;
  }

  // Check if data.dataset is defined and not empty
  const dataset = data.dataset;
  console.log("this is a data of dataset :", dataset);
  if (!dataset || dataset.length === 0) {
    return <div>No data available at this time</div>;
  }

  return (
    <div style={cardCss}>
      <h3>{chart_title}</h3>
      <Chart
        options={options}
        series={dataset}
        type={chart_type}
        // width={widthType === "full" ? "100%" : "auto"}
        width="100%"
        height={options?.chart?.height}
      />
    </div>
  );
};

export default ScatterChartTesting;
