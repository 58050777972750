/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../../../dashboard/Chart.css";

const CountOfChart = ({ chart_title, chart_type, data, cardCss, height }) => {
  return (
    <div style={cardCss} class="col-lg-3 col-6 main__card">
      <div class="inner">
        <div style={data?.card_css} className="count__data">
          {data.dataset.map((dataset, index) => (
            <div key={index}>
              <span>{dataset.data[0]}</span>

              <p>{chart_title}</p>
            </div>
          ))}
        </div>
        <div class="icon">
          <i class="bi bi-bar-chart-fill"></i>
        </div>
      </div>
      <div className="more__info">
        More Info <i class="bi bi-arrow-right-circle"></i>
      </div>
    </div>
  );
};

export default CountOfChart;
