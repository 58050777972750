// data/tableHeaders.js
const tableHeaders = [
  "Date",
  "Time",
  "Call_ID",
  "JSON",
  "Caller_ID",
  "Caller_Number",
  "Caller_Status",
  "Destination_Number",
  "Destination_Name",
  "Overall_Call_Status",
];

export default tableHeaders;
