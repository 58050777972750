// import React from "react";
// import Chart from "react-apexcharts";

// const LineChart = () => {
//   // Sample data for answered and missed calls
//   const chartData = [
//     {
//       date: "2023-05-01",
//       answered: 20,
//       missed: 10,
//     },
//     {
//       date: "2023-05-02",
//       answered: 15,
//       missed: 5,
//     },
//     // Add more data points for other dates
//   ];

//   // Extracting the dates and call counts for the chart
//   const chartSeries = [
//     {
//       name: "Answered",
//       data: chartData.map((item) => ({
//         x: item.date,
//         y: item.answered,
//       })),
//     },
//     {
//       name: "Missed",
//       data: chartData.map((item) => ({
//         x: item.date,
//         y: item.missed,
//       })),
//     },
//   ];

//   // Chart options
//   const chartOptions = {
//     chart: {
//       type: "line",
//     },
//     xaxis: {
//       type: "datetime",
//     },
//     yaxis: {
//       title: {
//         text: "Call Count",
//       },
//     },
//     series: chartSeries,
//   };

//   return (
//     <div>
//       <Chart
//         options={chartOptions}
//         series={chartSeries}
//         type="line"
//         height={250}
//       />
//     </div>
//   );
// };

// export default LineChart;

import React from "react";
import Chart from "react-apexcharts";

const LineChart = () => {
  const seriesLine = [
    {
      name: "Answered",
      data: [
        { x: "2022-01-01", y: 10 },
        { x: "2022-02-01", y: 41 },
        { x: "2022-03-01", y: 35 },
        { x: "2022-04-01", y: 51 },
        { x: "2022-05-01", y: 49 },
        { x: "2022-06-01", y: 62 },
        { x: "2022-07-01", y: 69 },
        { x: "2022-08-01", y: 91 },
        { x: "2022-09-01", y: 148 },
      ],
    },
    {
      name: "Missed",
      data: [
        { x: "2022-01-01", y: 5 },
        { x: "2022-02-01", y: 22 },
        { x: "2022-03-01", y: 15 },
        { x: "2022-04-01", y: 35 },
        { x: "2022-05-01", y: 30 },
        { x: "2022-06-01", y: 45 },
        { x: "2022-07-01", y: 40 },
        { x: "2022-08-01", y: 55 },
        { x: "2022-09-01", y: 80 },
      ],
    },
  ];

  const optionsLine = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    title: {
      text: "Product Trends by Month",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: "MMM",
      },
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    markers: {
      size: 4,
      colors: ["#008FFB", "#FF4560"],
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
  };

  return (
    <Chart options={optionsLine} series={seriesLine} type="line" height={250} />
  );
};

export default LineChart;
