// import React from "react";

// const Index = ({ data }) => {
//   const { dataset } = data.data;

//   if (!dataset || dataset.length === 0) {
//     return <div>No data available.</div>;
//   }

//   const headers = dataset.map((item) => item.label);
//   const maxLength = Math.max(...dataset.map((item) => item.data.length));
//   const rows = Array.from({ length: maxLength }, (_, index) =>
//     headers.map((header) => {
//       const dataItem = dataset.find((item) => item.label === header).data;
//       return dataItem[index] !== undefined ? dataItem[index] : "";
//     })
//   );

//   return (
//     <div className="table-responsive">
//       <table className="table">
//         <thead>
//           <tr>
//             {headers.map((header, index) => (
//               <th key={index}>{header}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {rows.map((row, index) => (
//             <tr key={index}>
//               {row.map((cell, index) => (
//                 <td key={index}>{cell}</td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default Index;

// import React, { useEffect, useRef } from "react";
// import $ from "jquery";
// import "datatables.net-dt/css/jquery.dataTables.css";
// import "datatables.net-dt/js/dataTables.dataTables";
// import "./style.css";

// import "datatables.net-buttons-dt/css/buttons.dataTables.css";
// import "datatables.net-buttons/js/dataTables.buttons";
// import "datatables.net-buttons/js/buttons.html5";
// import JSZip from "jszip";

// const Index = ({ data }) => {
//   const { dataset } = data.data;
//   const tableRef = useRef(null);

//   useEffect(() => {
//     $(tableRef.current).DataTable();
//   }, []);

//   if (!dataset || dataset.length === 0) {
//     return <div>No data available.</div>;
//   }

//   const headers = dataset.map((item) => item.label);
//   const maxLength = Math.max(...dataset.map((item) => item.data.length));
//   const rows = Array.from({ length: maxLength }, (_, index) =>
//     headers.map((header) => {
//       const dataItem = dataset.find((item) => item.label === header).data;
//       return dataItem[index] !== undefined ? dataItem[index] : "";
//     })
//   );

//   useEffect(() => {
//     $(tableRef.current).DataTable({
//       dom: "Bfrtip", // Add buttons to the DataTable
//       buttons: [
//         {
//           extend: "excelHtml5", // Export to Excel
//           text: "Export to Excel",
//         },
//         {
//           extend: "pdfHtml5", // Export to PDF
//           text: "Export to PDF",
//         },
//         {
//           extend: "csvHtml5", // Export to CSV
//           text: "Export to CSV",
//         },
//       ],
//       language: {
//         // Customize the DataTable language options
//         search: "Search:",
//         lengthMenu: "Show _MENU_ entries",
//         info: "Showing _START_ to _END_ of _TOTAL_ entries",
//         infoEmpty: "Showing 0 to 0 of 0 entries",
//         emptyTable: "No data available.",
//       },
//     });
//   }, []);
//   return (
//     <div className="table-responsive">
//       <table className="table" ref={tableRef}>
//         <thead>
//           <tr>
//             {headers.map((header, index) => (
//               <th key={index}>{header}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {rows.map((row, index) => (
//             <tr key={index}>
//               {row.map((cell, index) => (
//                 <td key={index}>{cell}</td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default Index;

// import React, { useEffect, useRef } from "react";
// import $ from "jquery";
// import "datatables.net-dt/css/jquery.dataTables.css";
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-buttons-dt/css/buttons.dataTables.css";
// import "datatables.net-buttons/js/dataTables.buttons";
// import "datatables.net-buttons/js/buttons.html5";
// import JSZip from "jszip";

// const Index = ({ data }) => {
//   const { dataset } = data.data;
//   const tableRef = useRef(null);

//   useEffect(() => {
//     $(tableRef.current).DataTable({
//       dom: "Bfrtip", // Add buttons to the DataTable
//       buttons: [
//         {
//           extend: "excelHtml5", // Export to Excel
//           text: "Export to Excel",
//         },
//         {
//           extend: "pdfHtml5", // Export to PDF
//           text: "Export to PDF",
//         },
//         {
//           extend: "csvHtml5", // Export to CSV
//           text: "Export to CSV",
//         },
//       ],
//       language: {
//         // Customize the DataTable language options
//         search: "Search:",
//         lengthMenu: "Show _MENU_ entries",
//         info: "Showing _START_ to _END_ of _TOTAL_ entries",
//         infoEmpty: "Showing 0 to 0 of 0 entries",
//         emptyTable: "No data available.",
//       },
//     });
//   }, []);

//   if (!dataset || dataset.length === 0) {
//     return <div>No data available.</div>;
//   }

//   const headers = dataset.map((item) => item.label);
//   const maxLength = Math.max(...dataset.map((item) => item.data.length));
//   const rows = Array.from({ length: maxLength }, (_, index) =>
//     headers.map((header) => {
//       const dataItem = dataset.find((item) => item.label === header).data;
//       return dataItem[index] !== undefined ? dataItem[index] : "";
//     })
//   );

//   return (
//     <div className="table-responsive">
//       <table className="table" ref={tableRef}>
//         <thead>
//           <tr>
//             {headers.map((header, index) => (
//               <th key={index}>{header}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {rows.map((row, index) => (
//             <tr key={index}>
//               {row.map((cell, index) => (
//                 <td key={index}>{cell}</td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default Index;

// import React, { useEffect, useRef } from "react";
// import $ from "jquery";
// import "datatables.net-dt/css/jquery.dataTables.css";
// import "datatables.net-dt/js/dataTables.dataTables";
// import "./style.css";

// const Index = ({ data }) => {
//   const { dataset } = data.data;
//   const tableRef = useRef(null);

//   useEffect(() => {
//     if ($.fn.DataTable.isDataTable(tableRef.current)) {
//       // DataTable already initialized, destroy it first
//       $(tableRef.current).DataTable().destroy();
//     }

//     $(tableRef.current).DataTable({
//       dom: "Bfrtip", // Add buttons to the DataTable
//       buttons: [
//         {
//           extend: "excelHtml5", // Export to Excel
//           text: "Export to Excel",
//         },
//         {
//           extend: "pdfHtml5", // Export to PDF
//           text: "Export to PDF",
//         },
//         {
//           extend: "csvHtml5", // Export to CSV
//           text: "Export to CSV",
//         },
//       ],
//       language: {
//         // Customize the DataTable language options
//         search: "Search:",
//         lengthMenu: "Show _MENU_ entries",
//         info: "Showing _START_ to _END_ of _TOTAL_ entries",
//         infoEmpty: "Showing 0 to 0 of 0 entries",
//         emptyTable: "No data available.",
//       },
//     });
//   }, [dataset]);

//   if (!dataset || dataset.length === 0) {
//     return <div>No data available.</div>;
//   }

//   const headers = dataset.map((item) => item.label);
//   const maxLength = Math.max(...dataset.map((item) => item.data.length));
//   const rows = Array.from({ length: maxLength }, (_, index) =>
//     headers.map((header) => {
//       const dataItem = dataset.find((item) => item.label === header).data;
//       return dataItem[index] !== undefined ? dataItem[index] : "";
//     })
//   );

//   return (
//     <div className="table-responsive">
//       <table className="table" ref={tableRef}>
//         <thead>
//           <tr>
//             {headers.map((header, index) => (
//               <th key={index}>{header}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {rows.map((row, index) => (
//             <tr key={index}>
//               {row.map((cell, index) => (
//                 <td key={index}>{cell}</td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default Index;

// import React, { useEffect, useRef } from "react";
// import $ from "jquery";
// import "datatables.net-dt/css/jquery.dataTables.css";
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-buttons-dt/css/buttons.dataTables.css";
// import "datatables.net-buttons/js/dataTables.buttons";
// import "datatables.net-buttons/js/buttons.html5";
// import "datatables.net-buttons/js/buttons.print";
// import "jszip";
// import "pdfmake";
// import "datatables.net-buttons/js/buttons.flash";
// import "./style.css";

// const Index = ({ data }) => {
//   const { dataset, card_css, options } = data;
//   const { height } = options?.chart || {};
//   // const height = options?.chart?.height || "auto"; // Get the height from options or default to an empty string

//   console.log("card_css:", card_css);

//   const tableRef = useRef(null);

//   useEffect(() => {
//     if (dataset && dataset.length > 0) {
//       if ($.fn.DataTable.isDataTable(tableRef.current)) {
//         // DataTable already initialized, destroy it first
//         $(tableRef.current).DataTable().destroy();
//       }

//       $(tableRef.current).DataTable({
//         scrollY: `${dataset.length * 52}px`,
//         // scrollY: height ? `${height}px` : "auto",

//         // scrollY: height || "auto",
//         dom: "Bfrtip", // Add buttons to the DataTable
//         buttons: [
//           {
//             extend: "excelHtml5", // Export to Excel
//             text: "Export to Excel",
//           },
//           {
//             extend: "pdfHtml5", // Export to PDF
//             text: "Export to PDF",
//           },
//           {
//             extend: "csvHtml5", // Export to CSV
//             text: "Export to CSV",
//           },
//         ],
//         language: {
//           // Customize the DataTable language options
//           search: "Search:",
//           lengthMenu: "Show _MENU_ entries",
//           info: "Showing _START_ to _END_ of _TOTAL_ entries",
//           infoEmpty: "Showing 0 to 0 of 0 entries",
//           emptyTable: "No data available.",
//         },
//       });
//     }
//   }, [dataset, height]);

//   if (!dataset || dataset.length === 0) {
//     return <div className={card_css}>No data available.</div>;
//   }

//   const maxLength = Math.max(...dataset.map((item) => item.data.length));
//   const headers = dataset.map((item) => item.label);
//   const rows = Array.from({ length: maxLength }, (_, index) =>
//     headers.map((header) => {
//       const dataItem = dataset.find((item) => item.label === header).data;
//       return dataItem[index] !== undefined ? dataItem[index] : "";
//     })
//   );

//   return (
//     <div className={`custom-card ${card_css}  `}>
//       <div className="table-responsive">
//         <table className="table" ref={tableRef}>
//           <thead>
//             <tr>
//               {headers.map((header, index) => (
//                 <th key={index}>{header}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {rows.map((row, index) => (
//               <tr key={index}>
//                 {row.map((cell, index) => (
//                   <td key={index}>{cell}</td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Index;

// import React, { useEffect, useRef } from "react";
// import $ from "jquery";
// import "datatables.net-dt/css/jquery.dataTables.css";
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-buttons-dt/css/buttons.dataTables.css";
// import "datatables.net-buttons/js/dataTables.buttons";
// import "datatables.net-buttons/js/buttons.html5";
// import "datatables.net-buttons/js/buttons.print";
// import "jszip";
// import "pdfmake";
// import "datatables.net-buttons/js/buttons.flash";
// import "./style.css";
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

// const Index = ({ data }) => {
//   const { dataset, card_css, options } = data;
//   const { height } = options?.chart || {};

//   const tableRef = useRef(null);

//   useEffect(() => {
//     if (dataset && dataset.length > 0) {
//       if ($.fn.DataTable.isDataTable(tableRef.current)) {
//         // DataTable already initialized, destroy it first
//         $(tableRef.current).DataTable().destroy();
//       }

//       $(tableRef.current).DataTable({
//         scrollY: `${dataset.length * 52}px`,
//         dom: "Bfrtip", // Add buttons to the DataTable
//         buttons: [
//           {
//             extend: "excelHtml5", // Export to Excel
//             text: "Export to Excel",
//           },
//           {
//             extend: "pdfHtml5", // Export to PDF
//             text: "Export to PDF",
//           },
//           {
//             extend: "csvHtml5", // Export to CSV
//             text: "Export to CSV",
//           },
//           {
//             text: "Export to JSON", // Custom JSON export button
//             action: function () {
//               const jsonData = JSON.stringify(dataset);
//               const blob = new Blob([jsonData], { type: "application/json" });
//               const url = URL.createObjectURL(blob);
//               const a = document.createElement("a");
//               a.href = url;
//               a.download = "data.json";
//               a.click();
//               URL.revokeObjectURL(url);
//             },
//           },
//         ],
//         language: {
//           // Customize the DataTable language options
//           search: "Search:",
//           lengthMenu: "Show _MENU_ entries",
//           info: "Showing _START_ to _END_ of _TOTAL_ entries",
//           infoEmpty: "Showing 0 to 0 of 0 entries",
//           emptyTable: "No data available.",
//         },
//       });
//     }
//   }, [dataset, height]);

//   if (!dataset || dataset.length === 0) {
//     return <div className={card_css}>No data available.</div>;
//   }

//   const maxLength = Math.max(...dataset.map((item) => item.data.length));
//   const headers = dataset.map((item) => item.label);
//   const rows = Array.from({ length: maxLength }, (_, index) =>
//     headers.map((header) => {
//       const dataItem = dataset.find((item) => item.label === header).data;
//       return dataItem[index] !== undefined ? dataItem[index] : "";
//     })
//   );

//   return (
//     <div className={`custom-card ${card_css}`}>
//       <div className="table-responsive">
//         <table className="table" ref={tableRef}>
//           <thead>
//             <tr>
//               {headers.map((header, index) => (
//                 <th key={index}>{header}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {rows.map((row, index) => (
//               <tr key={index}>
//                 {row.map((cell, index) => (
//                   <td key={index}>{cell}</td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Index;

import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "jszip";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "./style.css";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Index = ({ data }) => {
  const { dataset, card_css, options } = data;
  const { height } = options?.chart || {};

  const tableRef = useRef(null);

  useEffect(() => {
    if (dataset && dataset.length > 0) {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        // DataTable already initialized, destroy it first
        $(tableRef.current).DataTable().destroy();
      }

      $(tableRef.current).DataTable({
        scrollY: `${dataset.length * 52}px`,
        dom: "Bfrtip", // Add buttons to the DataTable
        buttons: [
          {
            extend: "excelHtml5", // Export to Excel
            text: "Export to Excel",
          },
          {
            extend: "pdfHtml5", // Export to PDF
            text: "Export to PDF",
            customize: function (doc) {
              // Customize the PDF document
              const table = doc.content[1].table;
              table.widths = Array(table.body[0].length).fill("*"); // Set column widths as * (auto)
            },
          },
          {
            extend: "csvHtml5", // Export to CSV
            text: "Export to CSV",
          },
          {
            text: "Export to JSON", // Custom JSON export button
            action: function () {
              const jsonData = JSON.stringify(dataset);
              const blob = new Blob([jsonData], { type: "application/json" });
              const url = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = "data.json";
              a.click();
              URL.revokeObjectURL(url);
            },
          },
        ],
        language: {
          // Customize the DataTable language options
          search: "Search:",
          lengthMenu: "Show _MENU_ entries",
          info: "Showing _START_ to _END_ of _TOTAL_ entries",
          infoEmpty: "Showing 0 to 0 of 0 entries",
          emptyTable: "No data available.",
        },
      });
    }
  }, [dataset, height]);

  if (!dataset || dataset.length === 0) {
    return <div className={card_css}>No data available.</div>;
  }

  const maxLength = Math.max(...dataset.map((item) => item.data.length));
  const headers = dataset.map((item) => item.label);
  const rows = Array.from({ length: maxLength }, (_, index) =>
    headers.map((header) => {
      const dataItem = dataset.find((item) => item.label === header).data;
      return dataItem[index] !== undefined ? dataItem[index] : "";
    })
  );

  return (
    <div className={`custom-card ${card_css}`}>
      <div className="table-responsive">
        <table className="table" ref={tableRef}>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                {row.map((cell, index) => (
                  <td key={index}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Index;
