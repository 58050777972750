// import React from "react";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Main from "./layout/Main";
// import protectedRoutes from "./routes/ProtectedRoutes";
// import "bootstrap/dist/css/bootstrap.min.css";

// // import css
// import "./assets/css/remixicon.css";

// // import scss
// import "./scss/style.scss";

// // set skin on load
// window.addEventListener("load", function () {
//   let skinMode = localStorage.getItem("skin-mode");
//   let HTMLTag = document.querySelector("html");

//   if (skinMode) {
//     HTMLTag.setAttribute("data-skin", skinMode);
//   }
// });

// export default function App() {
//   return (
//     <React.Fragment>
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<Main />}>
//             {protectedRoutes.map((route, index) => {
//               return (
//                 <Route path={route.path} element={route.element} key={index} />
//               );
//             })}
//           </Route>
//           <Route></Route>
//         </Routes>
//       </BrowserRouter>
//     </React.Fragment>
//   );
// }

import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./layout/Main";
import protectedRoutes from "./routes/ProtectedRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/remixicon.css";
import "./scss/style.scss";

// Component to handle skin mode setting
const SkinLoader = () => {
  useEffect(() => {
    let skinMode = localStorage.getItem("skin-mode");
    let HTMLTag = document.querySelector("html");

    if (skinMode) {
      HTMLTag.setAttribute("data-skin", skinMode);
    }
  }, []);

  return null;
};

export default function App() {
  return (
    <React.Fragment>
      {/* Include CSS imports and skin mode setting component here */}
      <SkinLoader />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}>
            {protectedRoutes.map((route, index) => {
              return (
                <Route path={route.path} element={route.element} key={index} />
              );
            })}
          </Route>
          {/* <Route> */}
          {/* Add a default route for handling 404 or fallback content */}
          {/* </Route> */}
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}
