// import React from "react";
// import Chart from "react-apexcharts";

// const DonutChart = () => {
//   const series = [30, 20, 25, 15];

//   const options = {
//     chart: {
//       type: "donut",
//       toolbar: {
//         show: false,
//       },
//     },
//     labels: ["Answered", "Missed", "Inbound", "Outbound"],
//     colors: ["#2ecc71", "#e74c3c", "#3498db", "#9b59b6"],
//     legend: {
//       show: true,
//       position: "bottom",
//       horizontalAlign: "center",
//       fontSize: "14px",
//       markers: {
//         width: 10,
//         height: 10,
//       },
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     plotOptions: {
//       pie: {
//         donut: {
//           size: "75%",
//         },
//         expandOnClick: false,
//       },
//     },
//     responsive: [
//       {
//         breakpoint: 480,
//         options: {
//           chart: {
//             width: "100%",
//           },
//           legend: {
//             position: "bottom",
//           },
//         },
//       },
//     ],
//   };

//   return <Chart options={options} series={series} type="donut" height={165} />;
// };

// export default DonutChart;

import React from "react";
import Chart from "react-apexcharts";
import { Card, Row, Col, Nav, ListGroup, Table } from "react-bootstrap";

const DonutChart = ({ data }) => {
  const { answered, missed, inbound, outbound } = data;

  const series = [answered, missed, inbound, outbound];

  const options = {
    chart: {
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    labels: ["Answered", "Missed", "Inbound", "Outbound"],
    colors: ["#2ecc71", "#e74c3c", "#3498db", "#9b59b6"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
      markers: {
        width: 10,
        height: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "75%",
        },
        expandOnClick: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <Row>
      <Col xl="6">
        <Chart options={options} series={series} type="donut" height={200} />
      </Col>
      <Col xl="3">
        <span></span>
        <span> {answered}</span> <br></br>
        <span>Answer</span> <br></br>
        <span>{missed}</span>
        <br></br>
        <span>Missed</span>
      </Col>
      <Col xl="3">
        <div>
          <span> {inbound}</span> <br></br>
          <span>Inbound:</span> <br></br>
          <span>{outbound}</span>
          <br></br>
          <span>Outbound: </span>
        </div>
      </Col>
    </Row>
  );
};

export default DonutChart;
