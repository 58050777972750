// cardData.js
export const cardData = [
  {
    icon: "ri-shopping-basket-fill",
    percent: {
      color: "danger",
      amount: " Average Conversation Duration(ms)",
    },
    value: "23,480",
    label: "Average conversation duration  of answer calls",
    last: {
      color: "danger",
    },
  },
  {
    icon: "ri-shopping-basket-fill",
    percent: {
      color: "success",
      amount: "Average customer wating  time(ms)",
    },
    value: "18,060",
    label: "Average from/customer wating time ",
    last: {
      color: "success",
    },
  },
  // Add more objects for additional cards...
];
