import React, { useRef, useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { CSVLink } from "react-csv";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./style.css";

const ChartExport = ({ chartData, exportPageRef }) => {
  const pageRef = useRef();
  const [showExportOptions, setShowExportOptions] = useState(false);

  const handlePDFExport = () => {
    const element = exportPageRef.current;

    if (!element) {
      console.log("Invalid element provided");
      return;
    }

    const options = {
      scrollY: -window.scrollY,
      useCORS: true,
      useProxy: true,
      scale: 2, // Adjust the scale value as needed to capture the complete content
    };

    // Add a delay before capturing the content
    setTimeout(() => {
      html2canvas(element, options).then((canvas) => {
        const pdf = new jsPDF("p", "mm", "a4");
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        const canvasRatio = canvas.width / canvas.height;
        const pageRatio = pageWidth / pageHeight;

        let imgWidth = pageWidth;
        let imgHeight = pageHeight;

        if (canvasRatio > pageRatio) {
          imgHeight = imgWidth / canvasRatio;
        } else {
          imgWidth = imgHeight * canvasRatio;
        }

        let position = 0;
        let windowHeight = window.innerHeight;
        let remainingHeight = canvas.height;

        while (remainingHeight > 0) {
          const visibleHeight = Math.min(remainingHeight, windowHeight);
          const imgDataUrl = canvas.toDataURL(
            "image/png",
            1.0,
            0,
            position,
            imgWidth,
            visibleHeight
          );

          pdf.addImage(imgDataUrl, "PNG", 0, 0, pageWidth, pageHeight);

          position += visibleHeight;
          remainingHeight -= visibleHeight;
        }

        pdf.save("chart_export.pdf");
      });
    }, 1000); // Adjust the delay time as needed
  };

  const handleImageExport = (format) => {
    const element = exportPageRef.current;

    if (!element) {
      console.log("Invalid element provided");
      return;
    }

    const options = {
      scrollY: -window.scrollY,
      useCORS: true,
      useProxy: true,
      scale: 2, // Adjust the scale value as needed to capture the complete content
    };

    // Add a delay before capturing the content
    setTimeout(() => {
      html2canvas(element, options).then((canvas) => {
        const imageFormat = format === "png" ? "image/png" : "image/jpeg";
        const imageQuality = format === "png" ? 1.0 : 0.8; // Adjust the quality value for JPEG

        canvas.toBlob(
          (blob) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `chart_export.${format}`;
            a.click();
            URL.revokeObjectURL(url);
          },
          imageFormat,
          imageQuality
        );
      });
    }, 1000); // Adjust the delay time as needed
  };

  const handleExportJSON = () => {
    const jsonData = JSON.stringify(chartData);
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "chart_export.json";
    a.click();
    URL.revokeObjectURL(url);
  };

  // const handleExportCSV = () => {
  //   // Convert data to CSV format
  //   const csvHeaders = Object.keys(chartData[0]); // Get the keys of the first object to use as CSV headers
  //   const csvData = chartData.map((item) => Object.values(item));

  //   // Create CSV content
  //   const csvContent = [csvHeaders, ...csvData]
  //     .map((row) => row.join(","))
  //     .join("\n");

  //   // Create a Blob object
  //   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  //   // Create a temporary URL for downloading the file
  //   const url = URL.createObjectURL(blob);

  //   // Create a link element and trigger the download
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "chart_export.csv";
  //   a.click();

  //   // Clean up the temporary URL
  //   URL.revokeObjectURL(url);
  // };

  // const handleExportCSV = () => {
  //   // Define the custom CSV headers
  //   const csvHeaders = ["Label", "Data", "Name"];

  //   // Extract the necessary data from the chartData object
  //   const csvData = chartData.map((item) => [item.label, item.data, item.name]);

  //   // Create CSV content
  //   const csvContent = [csvHeaders, ...csvData]
  //     .map((row) => row.join(","))
  //     .join("\n");

  //   // Create a Blob object
  //   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  //   // Create a temporary URL for downloading the file
  //   const url = URL.createObjectURL(blob);

  //   // Create a link element and trigger the download
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "chart_export.csv";
  //   a.click();

  //   // Clean up the temporary URL
  //   URL.revokeObjectURL(url);
  // };

  // const handleExportCSV = () => {
  //   // Extract the categories (labels)
  //   const categories = chartData.map((item) => item.label);

  //   // Extract the unique names
  //   const uniqueNames = [...new Set(chartData.map((item) => item.name))];

  //   // Generate the CSV headers
  //   const csvHeaders = ["Category", ...uniqueNames];

  //   // Generate the CSV rows
  //   const csvRows = categories.map((category) => {
  //     const rowData = [category];
  //     uniqueNames.forEach((name) => {
  //       const matchingItem = chartData.find(
  //         (item) => item.label === category && item.name === name
  //       );
  //       rowData.push(matchingItem ? matchingItem.data : "");
  //     });
  //     return rowData;
  //   });

  //   // Create CSV content
  //   const csvContent = [csvHeaders, ...csvRows]
  //     .map((row) => row.join(","))
  //     .join("\n");

  //   // Create a Blob object
  //   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  //   // Create a temporary URL for downloading the file
  //   const url = URL.createObjectURL(blob);

  //   // Create a link element and trigger the download
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "chart_export.csv";
  //   a.click();

  //   // Clean up the temporary URL
  //   URL.revokeObjectURL(url);
  // };

  const handleExportCSV = (chartData) => {
    // Extract categories (labels) from chartData
    // Check if chartData is an array
    if (!Array.isArray(chartData)) {
      console.error("chartData is not an array.");
      return;
    }

    const categories = chartData.map((item) => item.category);

    // Extract unique names (headers) from chartData
    const uniqueNames = [...new Set(chartData.map((item) => item.name))];

    // Generate the CSV headers
    const csvHeaders = ["category", ...uniqueNames];

    // Generate the CSV rows
    const csvRows = categories.map((category) => {
      const rowData = [category];
      uniqueNames.forEach((name) => {
        const matchingItem = chartData.find(
          (item) => item.category === category && item.name === name
        );
        rowData.push(matchingItem ? matchingItem.data : "");
      });
      return rowData;
    });

    // Create CSV content
    const csvContent = [csvHeaders.join(","), ...csvRows]
      .map((row) => row.join(","))
      .join("\n");

    // Create a Blob object
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a temporary URL for downloading the file
    const url = URL.createObjectURL(blob);

    // Create a link element and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "chart_export.csv";
    a.click();

    // Clean up the temporary URL
    URL.revokeObjectURL(url);
  };

  const toggleExportOptions = () => {
    setShowExportOptions(!showExportOptions);
  };

  return (
    <div>
      {/* Your chart component */}
      <div ref={pageRef}>{/* Chart content */}</div>

      {/* Export button */}
      <DropdownButton
        className="custom-dropdown"
        id="custom-dropdown"
        title={<i class="bi bi-three-dots-vertical dots  "></i>}
        onClick={toggleExportOptions}
        drop="right"
      >
        <div>
          <Dropdown.Item onClick={handlePDFExport}>Export as PDF</Dropdown.Item>
          <Dropdown.Item onClick={() => handleImageExport("png")}>
            Export as PNG
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleImageExport("jpeg")}>
            Export as JPEG
          </Dropdown.Item>
          <Dropdown.Item onClick={handleExportJSON}>
            Export as JSON
          </Dropdown.Item>
          <Dropdown.Item onClick={handleExportCSV}>Export as CSV</Dropdown.Item>
        </div>
      </DropdownButton>
    </div>
  );
};

export default ChartExport;
