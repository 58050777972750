// import Chart from "react-apexcharts";
// import React, { useEffect, useState, useRef } from "react";

// // import ExportButtons from "../../ExportButtons/ExportButtons";
// import ChartExport from "../../ExportUtils/ChartExport/ChartExport";

// const AreaChartMain = ({
//   chart_title,
//   chart_type,
//   data,
//   cardCss,
//   widthType,
//   options,
//   series,
// }) => {
//   console.log("this is the data but both chart", chart_type);
//   const pageRef = useRef();

//   // Check if data is defined
//   if (!data) {
//     return <div>No data available</div>;
//   }

//   // Check if data.dataset is defined and not empty
//   const dataset = data.dataset;
//   console.log(
//     "this is a data of dataset for export fir further uses :",
//     dataset
//   );
//   if (!dataset || dataset.length === 0) {
//     return <div>No data available at this time</div>;
//   }

//   return (
//     <div style={cardCss}>
//       <h4 className="ml-3">{chart_title}</h4>
//       {/* <div className="export-buttons justify-content-end mb-3">
//         <ExportUtils exportData={exportData} exportPageRef={pageRef} />
//       </div> */}
//       <ChartExport chartData={dataset} exportPageRef={pageRef} />

//       <div ref={pageRef}>
//         <Chart
//           options={options}
//           series={dataset}
//           type={chart_type}
//           width="100%"
//           height={options?.chart?.height}
//         />
//       </div>
//     </div>
//   );
// };

// export default AreaChartMain;

// import React, { useState, useRef } from "react";
// import Chart from "react-apexcharts";
// import ChartExport from "../../ExportUtils/ChartExport/ChartExport";
// import "./styles.css";

// const AreaChartMain = ({
//   chart_title,
//   chart_type,
//   data,
//   cardCss,
//   widthType,
//   options,
//   series,
// }) => {
//   const [isMaximized, setIsMaximized] = useState(false);
//   const chartRef = useRef();

//   const toggleMaximize = () => {
//     setIsMaximized((prevMaximized) => !prevMaximized);
//   };

//   // Check if data is defined
//   if (!data) {
//     return <div>No data available</div>;
//   }

//   // Check if data.dataset is defined and not empty
//   const dataset = data.dataset;
//   if (!dataset || dataset.length === 0) {
//     return <div>No data available at this time</div>;
//   }

//   return (
//     <div
//       style={cardCss}
//       className={`chart-container ${isMaximized ? "maximized" : ""}`}
//     >
//       <h4 className="ml-3">{chart_title}</h4>
//       <ChartExport chartData={dataset} exportPageRef={chartRef} />

//       <div
//         ref={chartRef}
//         className={`chart-wrapper ${isMaximized ? "maximized" : ""}`}
//       >
//         <Chart
//           options={options}
//           series={dataset}
//           type={chart_type}
//           width="100%"
//           height={options?.chart?.height}
//         />
//       </div>

//       <div className="maximize-button" onClick={toggleMaximize}>
//         {isMaximized ? "Minimize" : "Maximize"}
//       </div>
//     </div>
//   );
// };

// export default AreaChartMain;

// import React, { useState, useRef } from "react";
// import Chart from "react-apexcharts";
// import ChartExport from "../../ExportUtils/ChartExport/ChartExport";
// import "./styles.css";

// const AreaChartMain = ({
//   chart_title,
//   chart_type,
//   data,
//   cardCss,
//   widthType,
//   options,
//   series,
// }) => {
//   const [isMaximized, setIsMaximized] = useState(false);
//   const chartRef = useRef();

//   const toggleMaximize = () => {
//     setIsMaximized((prevMaximized) => !prevMaximized);
//   };

//   // Check if data is defined
//   if (!data) {
//     return <div>No data available</div>;
//   }

//   // Check if data.dataset is defined and not empty
//   const dataset = data.dataset;
//   if (!dataset || dataset.length === 0) {
//     return <div>No data available at this time</div>;
//   }

//   return (
//     <div
//       style={cardCss}
//       className={`chart-container ${isMaximized ? "maximized" : ""}`}
//     >
//       <h4 className="ml-3">{chart_title}</h4>
//       <ChartExport chartData={dataset} exportPageRef={chartRef} />

//       <div
//         ref={chartRef}
//         className={`chart-wrapper ${isMaximized ? "maximized" : ""}`}
//       >
//         <Chart
//           options={options}
//           series={dataset}
//           type={chart_type}
//           width={isMaximized ? "100%" : "100%"}
//           height={options?.chart?.height}
//         />
//       </div>

//       <div className="maximize-button" onClick={toggleMaximize}>
//         {isMaximized ? "Minimize" : "Maximize"}
//       </div>
//     </div>
//   );
// };

// export default AreaChartMain;

// import React, { useState, useRef } from "react";
// import Chart from "react-apexcharts";
// import ChartExport from "../../ExportUtils/ChartExport/ChartExport";
// import "./styles.css";

// const AreaChartMain = ({
//   chart_title,
//   chart_type,
//   data,
//   cardCss,
//   widthType,
//   options,
//   series,
// }) => {
//   const [isFullScreen, setIsFullScreen] = useState(false);
//   const chartRef = useRef();

//   const toggleFullScreen = () => {
//     setIsFullScreen(!isFullScreen);
//   };

//   // Check if data is defined
//   if (!data) {
//     return <div>No data available</div>;
//   }

//   // Check if data.dataset is defined and not empty
//   const dataset = data.dataset;
//   if (!dataset || dataset.length === 0) {
//     return <div>No data available at this time</div>;
//   }

//   return (
//     <div
//       style={cardCss}
//       className={`chart-container ${isFullScreen ? "fullscreen" : ""}`}
//     >
//       <h4 className="ml-3">{chart_title}</h4>
//       <ChartExport chartData={dataset} exportPageRef={chartRef} />

//       <div
//         ref={chartRef}
//         className={`chart-wrapper ${isFullScreen ? "fullscreen" : ""}`}
//       >
//         <Chart
//           options={options}
//           series={dataset}
//           type={chart_type}
//           width="100%"
//           height={options?.chart?.height}
//         />
//       </div>

//       <div className="maximize-button" onClick={toggleFullScreen}>
//         {isFullScreen ? "Exit Fullscreen" : "Fullscreen"}
//       </div>
//     </div>
//   );
// };

// export default AreaChartMain;

// import React, { useState, useRef } from "react";
// import Chart from "react-apexcharts";
// import ChartExport from "../../ExportUtils/ChartExport/ChartExport";
// import "./styles.css";

// const AreaChartMain = ({
//   chart_title,
//   chart_type,
//   data,
//   cardCss,
//   widthType,
//   options,
//   series,
// }) => {
//   const [isMaximized, setIsMaximized] = useState(false);
//   const chartRef = useRef();
//   const originalChartOptions = options; // Store the original chart options
//   const originalCardCss = cardCss; // Store the original card CSS

//   const toggleMaximize = () => {
//     setIsMaximized((prevMaximized) => !prevMaximized);
//   };

//   // Check if data is defined
//   if (!data) {
//     return <div>No data available</div>;
//   }

//   // Check if data.dataset is defined and not empty
//   const dataset = data.dataset;
//   if (!dataset || dataset.length === 0) {
//     return <div>No data available at this time</div>;
//   }

//   const chartOptions = isMaximized ? originalChartOptions : options; // Use original options when not maximized
//   const cardStyles = isMaximized
//     ? {
//         ...originalCardCss,
//         position: "fixed",
//         top: 0,
//         left: 0,
//         right: 0,
//         bottom: 0,
//         zIndex: 9999,
//       }
//     : originalCardCss; // Update card styles when maximized

//   return (
//     <div
//       style={cardStyles}
//       className={`chart-container ${isMaximized ? "maximized" : ""}`}
//     >
//       <h4 className="ml-3">{chart_title}</h4>
//       <ChartExport chartData={dataset} exportPageRef={chartRef} />

//       <div ref={chartRef} className="chart-wrapper">
//         <Chart
//           options={chartOptions}
//           series={dataset}
//           type={chart_type}
//           width="100%"
//           height={isMaximized ? "100vh" : options?.chart?.height}
//         />
//       </div>

//       <div className="maximize-button" onClick={toggleMaximize}>
//         {isMaximized ? "Minimize" : "Maximize"}
//       </div>
//     </div>
//   );
// };

// export default AreaChartMain;

// import React, { useState, useRef } from "react";
// import Chart from "react-apexcharts";
// import ChartExport from "../../ExportUtils/ChartExport/ChartExport";
// import "./styles.css";

// const AreaChartMain = ({
//   chart_title,
//   chart_type,
//   data,
//   cardCss,
//   widthType,
//   options,
//   series,
// }) => {
//   const [isMaximized, setIsMaximized] = useState(false);
//   const chartRef = useRef();
//   const originalChartOptions = options; // Store the original chart options
//   const originalCardCss = cardCss; // Store the original card CSS

//   const toggleMaximize = () => {
//     setIsMaximized((prevMaximized) => !prevMaximized);
//   };

//   // Check if data is defined
//   if (!data) {
//     return <div>No data available</div>;
//   }

//   // Check if data.dataset is defined and not empty
//   const dataset = data.dataset;
//   if (!dataset || dataset.length === 0) {
//     return <div>No data available at this time</div>;
//   }

//   const chartOptions = isMaximized ? originalChartOptions : options; // Use original options when not maximized
//   const cardStyles = isMaximized
//     ? {
//         ...originalCardCss,
//         position: "fixed",
//         top: 0,
//         left: 0,
//         right: 0,
//         bottom: 0,
//         zIndex: 9999,
//       }
//     : originalCardCss; // Update card styles when maximized

//   // Calculate the height based on the aspect ratio of the chart
//   const calculateHeight = () => {
//     const aspectRatio = options.chart.height / options.chart.width;
//     const width = window.innerWidth;
//     const height = width * aspectRatio;
//     return isMaximized ? height : options.chart.height;
//   };

//   return (
//     <div
//       style={cardStyles}
//       className={`chart-container ${isMaximized ? "maximized" : ""}`}
//     >
//       <div className="d-flex justify-content: space-between;">
//         <h4 className="ml-3">{chart_title}</h4>
//         <ChartExport chartData={dataset} exportPageRef={chartRef} />
//       </div>

//       <div
//         ref={chartRef}
//         className="chart-wrapper"
//         style={{ height: calculateHeight() }}
//       >
//         <Chart
//           options={chartOptions}
//           series={dataset}
//           type={chart_type}
//           width="100%"
//           height={isMaximized ? "100%" : options?.chart?.height}
//         />
//       </div>

//       <div className="maximize-button" onClick={toggleMaximize}>
//         {isMaximized ? "Minimize" : "Maximize"}
//       </div>
//     </div>
//   );
// };

// export default AreaChartMain;

// import React, { useState, useRef } from "react";
// import Chart from "react-apexcharts";
// import ChartExport from "../../ExportUtils/ChartExport/ChartExport";
// import "./styles.css";

// const AreaChartMain = ({
//   chart_title,
//   chart_type,
//   data,
//   cardCss,
//   widthType,
//   options,
//   series,
// }) => {
//   const [isMaximized, setIsMaximized] = useState(false);
//   const chartRef = useRef();
//   const originalChartOptions = options; // Store the original chart options
//   const originalCardCss = cardCss; // Store the original card CSS

//   const toggleMaximize = () => {
//     setIsMaximized((prevMaximized) => !prevMaximized);
//   };

//   // Check if data is defined
//   if (!data) {
//     return <div>No data available</div>;
//   }

//   // Check if data.dataset is defined and not empty
//   const dataset = data.dataset;
//   if (!dataset || dataset.length === 0) {
//     return <div>No data available at this time</div>;
//   }

//   const chartOptions = isMaximized ? originalChartOptions : options; // Use original options when not maximized
//   const cardStyles = isMaximized
//     ? {
//         ...originalCardCss,
//         position: "fixed",
//         top: 0,
//         left: 0,
//         right: 0,
//         bottom: 0,
//         zIndex: 9999,
//       }
//     : originalCardCss; // Update card styles when maximized

//   // Calculate the height based on the aspect ratio of the chart
//   const calculateHeight = () => {
//     const aspectRatio = options.chart.height / options.chart.width;
//     const width = window.innerWidth;
//     const height = width * aspectRatio;
//     return isMaximized ? height : options.chart.height;
//   };

//   const chartContainerStyle = {
//     transform: isMaximized ? "scale(1.1)" : "scale(1)",
//     transition: "transform 0.3s ease",
//   };

//   return (
//     <div
//       style={cardStyles}
//       className={`chart-container ${isMaximized ? "maximized" : ""}`}
//     >
//       <div className="d-flex justify-content: space-between;">
//         <h4 className="ml-3">{chart_title}</h4>
//         <ChartExport chartData={dataset} exportPageRef={chartRef} />
//       </div>

//       <div
//         ref={chartRef}
//         className="chart-wrapper"
//         style={{ height: calculateHeight() }}
//       >
//         <div style={chartContainerStyle}>
//           <Chart
//             options={chartOptions}
//             series={dataset}
//             type={chart_type}
//             width="100%"
//             height={isMaximized ? "100%" : options?.chart?.height}
//           />
//         </div>
//       </div>

//       <div className="maximize-button" onClick={toggleMaximize}>
//         {isMaximized ? "Minimize" : "Maximize"}
//       </div>
//     </div>
//   );
// };

// export default AreaChartMain;

import React, { useState, useRef } from "react";
import Chart from "react-apexcharts";
import ChartExport from "../../ExportUtils/ChartExport/ChartExport";

import "./styles.css";

const AreaChartMain = ({
  chart_title,
  chart_type,
  data,
  cardCss,
  widthType,
  options,
  series,
}) => {
  const [isMaximized, setIsMaximized] = useState(false);
  const chartRef = useRef();
  const originalChartOptions = options; // Store the original chart options
  const originalCardCss = cardCss; // Store the original card CSS

  const toggleMaximize = () => {
    setIsMaximized((prevMaximized) => !prevMaximized);
  };

  // Check if data is defined
  if (!data) {
    return <div>No data available</div>;
  }

  // Check if data.dataset is defined and not empty
  const dataset = data.dataset;
  if (!dataset || dataset.length === 0) {
    return <div>No data available at this time</div>;
  }

  const chartOptions = isMaximized ? originalChartOptions : options; // Use original options when not maximized
  const cardStyles = isMaximized
    ? {
        ...originalCardCss,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
      }
    : originalCardCss; // Update card styles when maximized

  // Calculate the height based on the aspect ratio of the chart
  const calculateHeight = () => {
    const aspectRatio = options.chart.height / options.chart.width;
    const width = window.innerWidth;
    const height = width * aspectRatio;
    return isMaximized ? height : options.chart.height;
  };

  const chartContainerStyle = {
    transform: isMaximized ? "scale(1.1)" : "scale(1)",
    transition: "transform 0.3s ease",
  };

  return (
    <div
      style={cardStyles}
      className={`chart-container ${isMaximized ? "maximized" : ""}`}
    >
      <div className="chrt_title_dots">
        <h4 className="ml-3">{chart_title}</h4>
        <div className="three_dots">
          <ChartExport chartData={dataset} exportPageRef={chartRef} />
        </div>
      </div>

      <div
        ref={chartRef}
        className="chart-wrapper"
        style={{ height: calculateHeight() }}
      >
        <div style={chartContainerStyle}>
          <Chart
            options={chartOptions}
            series={dataset}
            type={chart_type}
            width="100%"
            height={isMaximized ? "100%" : options?.chart?.height}
          />
        </div>
      </div>

      {/* <div className="maximize-button" onClick={toggleMaximize}>
        {isMaximized ? "Minimize" : "Maximize"}
      </div> */}

      <div className="maximize-button" onClick={toggleMaximize}>
        {isMaximized ? (
          <i class="bi bi-fullscreen-exit"></i>
        ) : (
          <i class="bi bi-fullscreen"></i>
        )}
      </div>
    </div>
  );
};

export default AreaChartMain;
