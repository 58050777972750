// import React from "react";
// import { Card, Row, Col, Nav, ListGroup, Table } from "react-bootstrap";
// import CardComponent from "../components/CardComponent";
// import DonutChart from "../components/DonutChart";
// const CallDashboard = () => {
//   return (
//     <React.Fragment>
//       <div>
//         <Row>
//           <Col xl="8">
//             <CardComponent />
//           </Col>
//         </Row>
//       </div>
//     </React.Fragment>
//   );
// };

// export default CallDashboard;

import {
  Button,
  Card,
  Col,
  Row,
  Nav,
  Table,
  ProgressBar,
  Form,
} from "react-bootstrap";
import CardComponent from "../components/CardComponent";
import { chartData } from "../data/CallDetails/chartData";
// import ScatterPlot from "../components/ScatterPlot";
import Header from "../layout/Header";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AreaChart from "../components/AreaChart";
import areaChartData from "../data/chartdata/areaChartData.json";
import LineChart from "../components/LineChartSample";
import lineChartData from "../data/chartdata/lineChartData.json";
import HistogramChart from "../components/HistogramChart";
import histogramChart from "../data/chartdata/histogramChart.json";
import ScatterChart from "../components/ScatterChartSample";
import ScatterPlot from "../data/chartdata/ScatterPlot.json";
import RegressionChart from "../components/RegressionChart";
import regressionData from "../data/chartdata/regressionData.json";
import HexplotChart from "../components/HexplotChart";
import hexplot from "../data/chartdata/hexplot.json";
import StackedBarChart from "../components/StackedBarChart";
import StackedBarChartData from "../data/chartdata/StackedBarChartData.json";
import BivariateLineChart from "../components/BivariateLineChart";
import BivariateLineChartData from "../data/chartdata/BivariateLineChartData.json";
import PairPlotChart from "../components/PairPlotChart";
import PairPlot from "../data/chartdata/PairPlot.json";
import DonutChartJson from "../components/DonutChartJson";
import DonutChartData from "../data/chartdata/DonutChartData.json";
import TableComponent from "../components/TableComponent";
import tableHeaders from "../data/headers/tableHeaders";
import rowData from "../data/headers/rowData.json";
import CardResusable from "../components/CardResusable";
import chartDataFinal from "../data/chartdata/chartDataFinal.json";
const CallDashboard = ({ data }) => {
  //   const { answered, missed, inbound, outbound } = data;
  // const { options, series } = chartData;

  const { options, series } = areaChartData;
  const { areaChart, bivariateLineChart } = chartDataFinal;
  // const [selectedChartType, setSelectedChartType] = useState("areaChart");
  // const [selectedChartPosition, setSelectedChartPosition] = useState({});

  const [selectedChartType, setSelectedChartType] = useState(null);
  const [selectedChartPosition, setSelectedChartPosition] = useState(null);

  const handleChartTypeChange = (e) => {
    setSelectedChartType(e.target.value);
  };

  // const handleChartPositionChange = (e, cardId) => {
  //   const position = parseInt(e.target.value);
  //   setSelectedChartPosition({ cardId, position });
  // };

  const handleChartPositionChange = (e) => {
    const position = parseInt(e.target.value);
    setSelectedChartPosition(position);
  };

  // const cards = [
  //   { id: 1 },
  //   { id: 2 },
  //   { id: 3 },
  //   { id: 4 },
  //   { id: 5 },
  //   { id: 6 },
  //   { id: 7 },
  //   { id: 8 },
  //   { id: 9 },
  //   { id: 10 },
  // ];

  // const renderChart = (chartType, options, series) => {
  //   if (chartType === "areaChart") {
  //     return <AreaChart options={options} series={series} />;
  //   } else if (chartType === "bivariateLineChart") {
  //     return <BivariateLineChart options={options} series={series} />;
  //   } else {
  //     return null;
  //   }
  // };

  const renderChart = (chartType) => {
    const options = chartDataFinal[chartType]?.options || {};
    const series = chartDataFinal[chartType]?.series || [];

    if (chartType === "area") {
      return <AreaChart options={options} series={series} />;
    } else if (chartType === "bivariateLineChart") {
      return <BivariateLineChart options={options} series={series} />;
    } else {
      return null;
    }
  };

  // const renderChartSelectionOptions = (cardId) => {
  //   return (
  //     <div>
  //       <select value={selectedChartType} onChange={handleChartTypeChange}>
  //         <option value="area">Area Chart</option>
  //         <option value="bivariateLineChart">Bivariate Line Chart</option>
  //       </select>
  //       <select
  //         value={selectedChartPosition[cardId]}
  //         onChange={(e) => handleChartPositionChange(e, cardId)}
  //       >
  //         {cards.map((card) => (
  //           <option key={card.id} value={card.id}>
  //             Card {card.id}
  //           </option>
  //         ))}
  //       </select>
  //     </div>
  //   );
  // };

  const renderCard = (cardId) => {
    if (selectedChartPosition === cardId) {
      return <div>{renderChart(selectedChartType)}</div>;
    } else {
      return null;
    }
  };

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link href="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Call Service
              </li>
            </ol>
            <h4 className="main-title mb-0">Welcome to Dashboard</h4>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <Button variant="white" className="btn-icon">
              <i className="ri-share-line fs-18 lh-1"></i>
            </Button>
            <Button variant="white" className="btn-icon">
              <i className="ri-printer-line fs-18 lh-1"></i>
            </Button>
            <Button variant="primary" className="btn-icon">
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>
            </Button>
          </div>
        </div>
        <Row>
          <Col xl="6">
            <CardComponent showDonutChart={true} chartData={chartData} />
          </Col>

          {/* <Col xl="6">
            <ScatterPlot />
          </Col> */}
          <Col xl="6">
            <Card className="card-one">
              <Card.Body>
                <div className="p-2">
                  <Row className="g-2">
                    <Col sm="6">
                      <h3 className="card-value mb-2">
                        <span className="fw-semibold">11.54 sec</span>
                      </h3>
                      <label className="card-title fw-thin text-dark mb-2">
                        Avaerage Conversation Duration
                      </label>
                      <h3 className="">
                        <span className="fw-semibold">26.54 sec</span>
                      </h3>
                      <label className="fw-thin">Avrage Session Duration</label>
                    </Col>
                    <Col sm="6">
                      <h3 className="card-value mb-2">
                        <span>15.04 sec</span>
                      </h3>
                      <label className="card-title fw-thin text-dark mb-2">
                        Monthly Revenue/Customer
                      </label>
                      <h3 className="">
                        <span className="fw-semibold">26.54 sec</span>
                      </h3>
                      <label className="fw-thin">Avrage Session Duration</label>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xs="12">
            <Card className="card-one mt-5">
              <Card.Header>
                <Card.Title as="h6">Agent Performance Score</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                {/* Other content */}
                <TableComponent headers={tableHeaders} rows={rowData} />
              </Card.Body>
            </Card>
          </Col>
          <Col xl="12">
            <CardResusable>
              <TableComponent headers={tableHeaders} rows={rowData} />
            </CardResusable>
          </Col>
          <Col xl="6" className="mt-5">
            <h3>This is a AreaChart data</h3>
            {/* <AreaChart /> */}
            <AreaChart options={options} series={series} />
          </Col>

          <Col xl="6" className="mt-5">
            <h3>This is a Line Chart </h3>
            <LineChart
              options={lineChartData.options}
              series={lineChartData.series}
            />
          </Col>

          <Col xl="6" className="mt-5">
            <HistogramChart
              options={histogramChart.options}
              series={histogramChart.series}
            />
          </Col>

          <Col xl="6">
            <h3> This is a Scatter Plot</h3>
            <ScatterChart
              options={ScatterPlot.options}
              series={ScatterPlot.series}
            />
          </Col>

          <Col xl="6" className="mt-5">
            <RegressionChart
              options={regressionData.options}
              series={regressionData.series}
            />
          </Col>

          <Col xl="6" className="mt-5">
            <HexplotChart options={hexplot.options} series={hexplot.series} />
          </Col>

          <Col xl="6" className="mt-5">
            <StackedBarChart
              options={StackedBarChartData.options}
              series={StackedBarChartData.series}
            />
          </Col>

          <Col xl="6" className="mt-5">
            <BivariateLineChart
              options={BivariateLineChartData.options}
              series={BivariateLineChartData.series}
            />
          </Col>

          <Col xl="6" className="mt-5">
            <DonutChartJson
              options={DonutChartData.options}
              series={DonutChartData.series}
              colors={DonutChartData.colors}
            />
          </Col>

          <Col xl="6">
            <h1>this is a area chart Second</h1>
            <AreaChart options={areaChart.options} series={areaChart.series} />
          </Col>
          <Col xl="6">
            <h1>Thisn is a Bivarinat Second chart</h1>
            <BivariateLineChart
              options={bivariateLineChart.options}
              series={bivariateLineChart.series}
            />
          </Col>
          <Col>
            <CardResusable></CardResusable>
            <CardResusable></CardResusable>
            <CardResusable></CardResusable>
            <CardResusable></CardResusable>
            <CardResusable></CardResusable>
            <CardResusable></CardResusable>
            <CardResusable></CardResusable>
            <CardResusable></CardResusable>
            <CardResusable></CardResusable>
            <CardResusable></CardResusable>
          </Col>

          {/* <div>
            <h1>Dashboard</h1>
            <Col>
              {cards.map((card) => (
                <CardResusable key={card.id}>
                  {renderChartSelectionOptions(card.id)}
                  {renderChart(
                    selectedChartType,
                    chartDataFinal[selectedChartType].options,
                    chartDataFinal[selectedChartType].series
                  )}
                </CardResusable>
              ))}
            </Col>
          </div> */}

          <div>
            <h1>Dashboard</h1>
            <select value={selectedChartType} onChange={handleChartTypeChange}>
              <option value="">Select Chart Type</option>
              <option value="area">Area Chart</option>
              <option value="bivariateLineChart">Bivariate Line Chart</option>
            </select>
            <select
              value={selectedChartPosition}
              onChange={handleChartPositionChange}
            >
              <option value="">Select Chart Position</option>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((position) => (
                <option key={position} value={position}>
                  Card {position}
                </option>
              ))}
            </select>
            <div>
              <Col>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((cardId) => (
                  <CardResusable key={cardId}>
                    {renderCard(cardId)}
                  </CardResusable>
                ))}
              </Col>
            </div>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default CallDashboard;
