import React from "react";
import Chart from "react-apexcharts";

const LineChartTwo = () => {
  const series = [
    {
      name: "Average Conversation Duration",
      data: [120, 180, 150, 200, 160, 240, 220],
    },
    {
      name: "Average Waiting Time",
      data: [90, 120, 100, 130, 110, 140, 120],
    },
  ];

  const options = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    markers: {
      size: 4,
      colors: "#2c98f0",
    },
    xaxis: {
      type: "datetime",
      categories: [
        "2023-05-01T01:00:00",
        "2023-05-01T02:00:00",
        "2023-05-01T03:00:00",
        "2023-05-01T04:00:00",
        "2023-05-01T05:00:00",
        "2023-05-01T06:00:00",
        "2023-05-01T07:00:00",
      ],
    },
    yaxis: {
      title: {
        text: "Average Conversation",
      },
    },
  };
  return (
    <div>
      <Chart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default LineChartTwo;
