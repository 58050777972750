/* eslint-disable no-undef */

import chartTesting from "../data/chartdata/chartTesting.json";

import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import chartComponents from "../chartMappings/chartMappings";
import Header from "../layout/Header";
import "./Chart.css";
import ExportUtils from "../components/ExportUtils/ExportUtils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Chart = () => {
  // Sort the charts based on their position

  const sortedCharts = chartTesting.sort((a, b) => a.position - b.position);
  const [exportData, setExportData] = useState([]);
  const pageRef = useRef();

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  // Filter out the "Count of Total Charts" data
  const countOfChartsData = sortedCharts.find((chart) => chart.position === 1);

  useEffect(() => {
    const data = sortedCharts.map((chart) => ({
      chart_title: chart.chart_title,
      chart_type: chart.chart_type,
      data: chart.data,
      card_css: chart.card_css,
      height: chart.options?.chart?.height,
    }));
    setExportData(data);
  }, [sortedCharts]);

  return (
    <div>
      <div className="main main-app p-3 p-lg-4 chart-page">
        <Header onSkin={setSkin} />

        <Container fluid>
          {/* Export Buttons */}
          <div className="export-buttons justify-content-end mb-3">
            <ExportUtils exportData={exportData} exportPageRef={pageRef} />
          </div>

          {/* Render Count of Total Charts */}
          <div ref={pageRef}>
            <Row>
              <Col xl="12">
                <div style={{ margin: "20px" }} className="count__card">
                  {countOfChartsData ? (
                    <chartComponents.count
                      chart_title={countOfChartsData.chart_title}
                      data={countOfChartsData.data}
                      chart_type={countOfChartsData.chart_type}
                      cardCss={countOfChartsData.card_css}
                      height={countOfChartsData.options?.chart?.height}
                    />
                  ) : (
                    <p>No data found</p>
                  )}
                </div>
              </Col>
            </Row>

            {/* Render other Charts */}
            <Row>
              {sortedCharts.map((chart, index) => {
                const ChartComponent = chartComponents[chart.chart_type];

                if (
                  chart.position !== 1 &&
                  ChartComponent &&
                  chart.visibility === true
                ) {
                  const marginStyle = {
                    margin: "8px",
                    width: chart.width_type === "half" ? "col-6" : "col-12",
                  };

                  return (
                    <Col
                      xl={chart.width_type === "half" ? "6" : "12"}
                      key={index}
                    >
                      <div style={marginStyle}>
                        <ChartComponent
                          chart_title={
                            <h5 className="card__title pt-4 ms-4">
                              {chart.chart_title}
                            </h5>
                          }
                          data={chart.data}
                          chart_type={chart.chart_type}
                          cardCss={chart.card_css}
                          series={chart.series}
                          widthType={chart.width_type}
                          options={chart.options}
                          height={chart.options?.chart?.height}
                        />
                      </div>
                    </Col>
                  );
                }

                return null;
              })}
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Chart;
