import React from "react";
import Chart from "react-apexcharts";

const DonutChartTesting = ({
  chart_title,
  chart_type,
  data,
  cardCss,
  widthType,
  options,
}) => {
  // // Check if data is defined
  if (!data) {
    return <div>No data available</div>;
  }

  // // Check if data.dataset is defined and not empty
  const dataset = data.dataset;
  console.log("Dataset fo dom=nut chart:", dataset);

  console.log("this is a data of dataset :", dataset);
  if (!dataset || dataset.length === 0) {
    return <div>No data available at this time</div>;
  }
  // Convert dataset to match series format
  const seriesData = dataset.map((item) => item.data);

  // Convert dataset to match labels format
  const labels = dataset.map((item) => item.label);

  return (
    <div style={cardCss}>
      <h3>{chart_title}</h3>
      <Chart
        options={options}
        series={seriesData}
        type={chart_type}
        labels={labels}
        width="100%"
        height={options?.chart?.height}
      />
    </div>
  );
};

export default DonutChartTesting;
