import {
  Button,
  Card,
  Col,
  Row,
  Nav,
  Table,
  ProgressBar,
  Form,
} from "react-bootstrap";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import LineChart from "../components/LineChart";
import CardComponent from "../components/CardComponent";
import { cardData } from "../data/cardData";
import LineChartTwo from "../components/LineChartTwo";
import Header from "../layout/Header";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const IvrDashboard = () => {
  const states = {
    hover: {
      filter: {
        type: "none",
      },
    },
    active: {
      filter: {
        type: "none",
      },
    },
  };

  // const seriesOne = [
  //   {
  //     data: [
  //       [0, 1000],
  //       [1, 600],
  //       [2, 500],
  //       [3, 400],
  //       [4, 600],
  //       [5, 500],
  //       [6, 800],
  //       [7, 1000],
  //       [8, 900],
  //       [9, 1100],
  //       [10, 1500],
  //       [11, 1700],
  //       [12, 1400],
  //       [13, 1300],
  //       [14, 1500],
  //       [15, 1300],
  //       [16, 1200],
  //       [17, 1000],
  //       [18, 1100],
  //       [19, 800],
  //       [20, 500],
  //       [21, 300],
  //       [22, 500],
  //       [23, 600],
  //       [24, 500],
  //       [25, 600],
  //       [26, 800],
  //       [27, 1000],
  //       [28, 900],
  //       [29, 800],
  //       [30, 500],
  //     ],
  //   },
  //   {
  //     data: [
  //       [0, 30],
  //       [1, 30],
  //       [2, 30],
  //       [3, 30],
  //       [4, 30],
  //       [5, 30],
  //       [6, 30],
  //       [7, 30],
  //       [8, 30],
  //       [9, 30],
  //       [10, 30],
  //       [11, 30],
  //       [12, 30],
  //       [13, 30],
  //       [14, 30],
  //       [15, 30],
  //       [16, 30],
  //       [17, 30],
  //       [18, 30],
  //       [19, 30],
  //       [20, 30],
  //       [21, 30],
  //       [22, 30],
  //       [23, 30],
  //       [24, 30],
  //       [25, 30],
  //       [26, 30],
  //       [27, 30],
  //       [28, 30],
  //       [29, 30],
  //       [30, 30],
  //     ],
  //   },
  //   {
  //     data: [
  //       [0, 800],
  //       [1, 600],
  //       [2, 500],
  //       [3, 400],
  //       [4, 600],
  //       [5, 500],
  //       [6, 800],
  //       [7, 1000],
  //       [8, 900],
  //       [9, 1100],
  //       [10, 1500],
  //       [11, 1700],
  //       [12, 1400],
  //       [13, 1300],
  //       [14, 1500],
  //       [15, 1300],
  //       [16, 1200],
  //       [17, 1000],
  //       [18, 1100],
  //       [19, 800],
  //       [20, 500],
  //       [21, 300],
  //       [22, 500],
  //       [23, 600],
  //       [24, 500],
  //       [25, 600],
  //       [26, 800],
  //       [27, 1000],
  //       [28, 900],
  //       [29, 800],
  //       [30, 500],
  //     ],
  //   },
  // ];

  // const optionOne = {
  //   chart: {
  //     parentHeightOffset: 0,
  //     stacked: true,
  //     toolbar: { show: false },
  //   },
  //   dataLabels: { enabled: false },
  //   grid: {
  //     borderColor: "rgba(72,94,144, 0.07)",
  //     padding: {
  //       top: -20,
  //       left: 5,
  //     },
  //   },
  //   states: states,
  //   colors: ["#506fd9", "#fff", "#c8ccd4"],
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "35%",
  //     },
  //   },
  //   stroke: {
  //     curve: "straight",
  //     lineCap: "square",
  //     width: 0,
  //   },
  //   tooltip: { enabled: false },
  //   fill: { opacity: 1 },
  //   legend: { show: false },
  //   xaxis: {
  //     type: "numeric",
  //     tickAmount: 11,
  //     decimalsInFloat: 0,
  //     labels: {
  //       style: {
  //         fontSize: "11px",
  //       },
  //     },
  //   },
  //   yaxis: {
  //     max: 4000,
  //     tickAmount: 8,
  //     labels: {
  //       style: {
  //         colors: ["#a2abb5"],
  //         fontSize: "11px",
  //       },
  //     },
  //   },
  // };

  const seriesOne = [
    {
      name: "Answered",
      data: [
        { x: "2023-06-17 00:01:03", y: 50 },
        { x: "2023-06-17 01:30:00", y: 40 },
        { x: "2023-06-17 04:15:25", y: 35 },
        { x: "2023-06-17 06:50:12", y: 45 },
        // Add more data points
      ],
    },
    {
      name: "Missed",
      data: [
        { x: "2023-06-17 00:01:03", y: 20 },
        { x: "2023-06-17 01:30:00", y: 10 },
        { x: "2023-06-17 04:15:25", y: 15 },
        { x: "2023-06-17 06:50:12", y: 25 },
        // Add more data points
      ],
    },
    // Add more series for additional bar charts
  ];

  const optionOne = {
    chart: {
      type: "bar",
      height: 200,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "dd MMM",
          hour: "HH:mm",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 60,
      tickAmount: 7,
      labels: {
        formatter: function (value) {
          return value.toFixed(0);
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
    colors: ["#506fd9", "#ff4560"],
  };
  // Volume By Currency
  const options = {
    chart: {
      type: "donut",
    },
    series: [60, 50],
    labels: ["Answer", "Missed"],
    colors: ["#1bb378", "#8dd9bb"],
    legend: {
      show: false,
    },
  };

  const optionsOnePie = {
    chart: {
      type: "donut",
    },
    series: [20, 40, 60, 15], // Update series instead of seriesOne
    labels: ["Gujrat", "Maharashtra", "Delhi", "Rajasthan"],
    colors: ["#e14955", "#fe874c", "#4ac293", "#fee5c1"],
    legend: {
      show: false,
    },
  };

  const optionsTwoPie = {
    chart: {
      type: "donut",
    },
    series: [20, 40, 60, 15], // Update series instead of seriesOne
    labels: ["Gujrat", "Maharashtra", "Delhi", "Rajasthan"],
    colors: ["#e14955", "#fe874c", "#337bdc", "#fee5c1"],
    legend: {
      show: false,
    },
  };

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link href="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                IVR
              </li>
            </ol>
            <h4 className="main-title mb-0">Welcome to Dashboard</h4>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <Button variant="white" className="btn-icon">
              <i className="ri-share-line fs-18 lh-1"></i>
            </Button>
            <Button variant="white" className="btn-icon">
              <i className="ri-printer-line fs-18 lh-1"></i>
            </Button>
            <Button variant="primary" className="btn-icon">
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>
            </Button>
          </div>
        </div>
        <Row>
          <Col xl="6">
            <Row className="g-3">
              {[
                {
                  icon: "ri-shopping-basket-fill",
                  percent: {
                    color: "danger",
                    amount: "Total Calls",
                  },
                  value: "23,480",
                  label: "Total Calls",
                  last: {
                    color: "danger",
                  },
                },
                {
                  icon: "ri-shopping-basket-fill",
                  percent: {
                    color: "success",
                    amount: "Pulse Count",
                  },
                  value: "18,060",
                  label: "Pulse Count",
                  last: {
                    color: "success",
                  },
                },
              ].map((item, index) => (
                <Col sm="6" lg="6" md="6" key={index}>
                  <Card className="card-one card-product">
                    <Card.Body className="p-3">
                      <div className="d-flex align-items-center  mb-5">
                        <div className="card-icon">
                          <i className={item.icon}></i>
                        </div>
                        <h6
                          className={
                            "fw-normal ff-numerals mb-0" + item.percent.color
                          }
                        >
                          {item.percent.amount}
                        </h6>
                      </div>
                      <h2 className="card-value ls--1 text-center">
                        {item.value}
                      </h2>
                      <label className="card-label fw-medium text-dark text-center">
                        {item.label}
                      </label>
                      <span className="d-flex gap-1 fs-xs">
                        <span
                          className={
                            "d-flex align-items-center text-" + item.last.color
                          }
                        >
                          <span className="ff-numerals">
                            {item.last.amount}
                          </span>
                          <i
                            className={
                              item.last.color === "success"
                                ? "ri-arrow-up-line"
                                : "ri-arrow-down-line"
                            }
                          ></i>
                        </span>
                      </span>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>

          <Col xl="6">
            <Card className="card-one">
              {/* <Card.Header>
                <Card.Title as="h6">Call Details</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav> 
              </Card.Header> */}
              <h6>Call Status Piechart</h6>
              <Card.Body>
                <Row>
                  <Col xl="6">
                    <div>
                      <Chart
                        options={options}
                        series={options.series}
                        type="donut"
                        height={165}
                      />
                    </div>
                  </Col>
                  <Col xl="6">
                    <Table className="  ">
                      <tbody>
                        {[
                          {
                            dot: "primary",
                            callType: "Answer",

                            success: true,
                          },

                          {
                            dot: "teal",
                            callType: "Missed",
                            success: false,
                          },
                        ].map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div>
                                {/* <span
                                className={"badge-dot bg-" + item.dot}
                              ></span>{" "} */}
                                <span className="">{item.callType}</span>
                              </div>
                            </td>
                            {/* <td>{item.amount}</td> */}
                            {/* <td>
                            <div
                              className={
                                "justify-content-end text-" +
                                (item.success ? "success" : "danger")
                              }
                            >
                              {item.percent}{" "}
                              <i
                                className={
                                  "ri-arrow-" +
                                  (item.success ? "up" : "down") +
                                  "-line"
                                }
                              ></i>
                            </div>
                          </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col xl="6">
            <Card className="card-one card-product-inventory  mt-3">
              <h6>Call Trend</h6>
              <Card.Body className="p-0">
                <ReactApexChart
                  series={seriesOne}
                  options={optionOne}
                  type="bar"
                  height={200}
                  className="apex-chart-twelve mt-4 pt-3"
                />
              </Card.Body>
            </Card>
          </Col>

          <Col xl="6" className="mt-3">
            <Card className="card-one">
              <h6>Geographic Pie</h6>
              <Card.Body>
                <Row>
                  <Col xl="6">
                    <div>
                      <Chart
                        options={optionsOnePie}
                        series={optionsOnePie.series}
                        type="donut"
                        height={230}
                      />
                    </div>
                  </Col>
                  <Col xl="6">
                    <Table className="  ">
                      <tbody>
                        {[
                          {
                            dot: "primary",
                            callType: "Gujrat",

                            success: true,
                          },

                          {
                            dot: "teal",
                            callType: "Maharashtra",
                            success: false,
                          },
                          {
                            dot: "teal",
                            callType: "Delhi",
                            success: false,
                          },
                          {
                            dot: "teal",
                            callType: "Rajasthan",
                            success: false,
                          },
                        ].map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div>
                                {/* <span
                                className={"badge-dot bg-" + item.dot}
                              ></span>{" "} */}
                                <span className="">{item.callType}</span>
                              </div>
                            </td>
                            {/* <td>{item.amount}</td> */}
                            {/* <td>
                            <div
                              className={
                                "justify-content-end text-" +
                                (item.success ? "success" : "danger")
                              }
                            >
                              {item.percent}{" "}
                              <i
                                className={
                                  "ri-arrow-" +
                                  (item.success ? "up" : "down") +
                                  "-line"
                                }
                              ></i>
                            </div>
                          </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col xl="6" className="mt-3">
            <Card className="card-one">
              <h6>Call Status split</h6>
              <Card.Body>
                <Row>
                  <Col xl="6">
                    <div>
                      <Chart
                        options={optionsTwoPie}
                        series={optionsTwoPie.series}
                        type="donut"
                        height={230}
                      />
                    </div>
                  </Col>
                  <Col xl="6">
                    <Table className="  ">
                      <tbody>
                        {[
                          {
                            dot: "primary",
                            callType: "Disconnect",

                            success: true,
                          },

                          {
                            dot: "teal",
                            callType: "Removed",
                            success: false,
                          },
                          {
                            dot: "teal",
                            callType: "No answer ",
                            success: false,
                          },
                          {
                            dot: "teal",
                            callType: "not reachable",
                            success: false,
                          },
                          {
                            dot: "teal",
                            callType: "Network Error",
                            success: false,
                          },
                          {
                            dot: "teal",
                            callType: "Removing",
                            success: false,
                          },
                        ].map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div>
                                {/* <span
                                className={"badge-dot bg-" + item.dot}
                              ></span>{" "} */}
                                <span className="">{item.callType}</span>
                              </div>
                            </td>
                            {/* <td>{item.amount}</td> */}
                            {/* <td>
                            <div
                              className={
                                "justify-content-end text-" +
                                (item.success ? "success" : "danger")
                              }
                            >
                              {item.percent}{" "}
                              <i
                                className={
                                  "ri-arrow-" +
                                  (item.success ? "up" : "down") +
                                  "-line"
                                }
                              ></i>
                            </div>
                          </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col xl="6" className="mt-3">
            <Card className="card-one">
              <h6>Call Status split</h6>
              <Card.Body>
                <LineChart />
              </Card.Body>
            </Card>
          </Col>

          <Col xl="12">
            <Row className=" mt-3 ">
              {cardData.map((card, index) => (
                <Col sm={12} lg={6} md={6} key={index} className="g-">
                  <CardComponent
                    title={card.label}
                    content={card.value}
                    // icon={card.icon}
                    percentColor={card.percent.color}
                    percentAmount={card.percent.amount}
                    // lastColor={card.last.color}
                  />
                </Col>
              ))}
            </Row>
          </Col>

          <Col xl="12">
            <Card className="card-one mt-3">
              <h6>Average Conversation Duration </h6>
              <Card.Body>
                <LineChartTwo />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default IvrDashboard;
