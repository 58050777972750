import React from "react";
import Chart from "react-apexcharts";

const LineChartTesting = ({
  chart_title,
  chart_type,
  data,
  cardCss,
  widthType,
  options,
}) => {
  // Check if data is defined
  if (!data) {
    return <div>No data available</div>;
  }

  // Check if data.dataset is defined and not empty
  const dataset = data.dataset;
  if (!dataset || dataset.length === 0) {
    return <div>No data available at this time</div>;
  }

  return (
    <div style={cardCss}>
      <h3>{chart_title}</h3>
      <Chart
        options={options}
        series={dataset}
        type={chart_type}
        // width={width_type === "full" ? "100%" : "50%"}
        width="100%"
        height={options?.chart?.height}
      />
    </div>
  );
};

export default LineChartTesting;
