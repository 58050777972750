import React from "react";
import { Card } from "react-bootstrap";
import DonutChart from "./DonutChart";

const CardComponent = ({
  title,
  content,
  icon,
  percentColor,
  percentAmount,
  lastColor,
  showDonutChart,
  chartData,
  data,
}) => {
  return (
    <Card>
      <Card.Body>
        {/* <Card.Title>{title}</Card.Title> */}
        <h6>{title}</h6>
        <Card.Text className="text-center">{content}</Card.Text>
        <div className="text-center">
          {/* <span className={icon}></span> */}
          <span className="text-center">{percentAmount}</span>
        </div>
        {/* Render other card content using the received props */}
        <div>{showDonutChart && <DonutChart data={chartData} />}</div>
      </Card.Body>
    </Card>
  );
};

export default CardComponent;
