import React from "react";
import Chart from "react-apexcharts";

const DonutChartJson = ({ options, series, colors }) => {
  return (
    <Chart options={options} series={series} type="donut" colors={colors} />
  );
};

export default DonutChartJson;
